.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.message-chars-left {
  max-width: 600px;
  width:100%;
  margin: auto;
  text-align: left;
}
input {
  max-width: 600px;
  width:100%;
  color: black !important;
}textarea {
  height: 10em;
  max-width: 600px;
  width:100%;
  color: black !important;
}
div[role='alert'] {
  color: red;
  margin-top: 1em;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
